import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ProductVariantByIdGQL,
  ProductVariantItemGQL,
  ProductVariantItemsGQL,
  ProductVariantItemsQueryVariables,
  ProductVariantSearchGQL,
} from './gql/product-variant.gql.generated';
import { IProductVariantItemObject, ProductVariantSearchObject } from './types/product-variant';

@Injectable()
export class ProductVariantService {
  constructor(
    private productVariantItemGQL: ProductVariantItemGQL,
    private productVariantItemsGQL: ProductVariantItemsGQL,
    private productVariantSearchGQL: ProductVariantSearchGQL,
    private productVariantbyIdGQL: ProductVariantByIdGQL,
  ) {}

  fetchItem(id: number): Observable<IProductVariantItemObject> {
    return this.productVariantItemGQL
      .fetch({ id })
      .pipe(mapFetchResultData((r) => r.productVariant));
  }

  fetchItems(
    query: ProductVariantItemsQueryVariables,
  ): Observable<{ items: IProductVariantItemObject[]; totalItemsCount: number }> {
    return this.productVariantItemsGQL.fetch(query).pipe(map((r) => r.data));
  }

  fetchVariantSearch(searchText: string): Observable<ProductVariantSearchObject[]> {
    return this.productVariantSearchGQL
      .fetch({ searchText })
      .pipe(mapFetchResultData((r) => r.productVariants));
  }

  fetchById(id: number): Observable<ProductVariantSearchObject> {
    return this.productVariantbyIdGQL.fetch({ id }).pipe(map((r) => r.data.productVariant));
  }
}
