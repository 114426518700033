import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { UserItemFragmentDoc, OrderSalesStatsFragmentDoc, OrderStateCountsFragmentDoc, UserDetailFragmentDoc } from './user.gql.generated';
import { ChannelRowFragmentDoc } from './channel.gql.generated';
import { OrderTemplateItemFragmentDoc } from './order-template.gql.generated';
import { OrderItemFragmentDoc } from './order.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type BulkOrderRowFragment = { __typename: 'BulkOrderObject', id: number, name: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, customerUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, template: { __typename: 'OrderTemplateObject', name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean } }, orderSalesStats: Array<{ __typename: 'OrderSalesStatsObject', currency: string, sumTotal: number, sumPaidTotal: number, sumAdjustmentsTotal: number, sumRefundedTotal: number }>, orderStateCounts: Array<{ __typename: 'OrderStateCountsObject', state: Types.OrderState, count: number }> };

export type BulkOrderDetailFragment = { __typename: 'BulkOrderObject', id: number, name: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, customerUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, template: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> }, orderSalesStats: Array<{ __typename: 'OrderSalesStatsObject', currency: string, sumTotal: number, sumPaidTotal: number, sumAdjustmentsTotal: number, sumRefundedTotal: number }>, orderStateCounts: Array<{ __typename: 'OrderStateCountsObject', state: Types.OrderState, count: number }> };

export type BulkOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type BulkOrderQuery = { __typename: 'Query', bulkOrder: { __typename: 'BulkOrderObject', id: number, name: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, customerUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, template: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> }, orderSalesStats: Array<{ __typename: 'OrderSalesStatsObject', currency: string, sumTotal: number, sumPaidTotal: number, sumAdjustmentsTotal: number, sumRefundedTotal: number }>, orderStateCounts: Array<{ __typename: 'OrderStateCountsObject', state: Types.OrderState, count: number }> } };

export type BulkOrderRowsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BulkOrderFilterInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type BulkOrderRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'BulkOrderObject', id: number, name: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, customerUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, template: { __typename: 'OrderTemplateObject', name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean } }, orderSalesStats: Array<{ __typename: 'OrderSalesStatsObject', currency: string, sumTotal: number, sumPaidTotal: number, sumAdjustmentsTotal: number, sumRefundedTotal: number }>, orderStateCounts: Array<{ __typename: 'OrderStateCountsObject', state: Types.OrderState, count: number }> }> };

export type CreateBulkOrderMutationVariables = Types.Exact<{
  input: Types.CreateBulkOrderInput;
}>;


export type CreateBulkOrderMutation = { __typename: 'Mutation', createBulkOrder: { __typename: 'BulkOrderObject', id: number, name: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, customerUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, template: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> }, orderSalesStats: Array<{ __typename: 'OrderSalesStatsObject', currency: string, sumTotal: number, sumPaidTotal: number, sumAdjustmentsTotal: number, sumRefundedTotal: number }>, orderStateCounts: Array<{ __typename: 'OrderStateCountsObject', state: Types.OrderState, count: number }> } };

export type UpdateBulkOrderMutationVariables = Types.Exact<{
  input: Types.UpdateBulkOrderInput;
}>;


export type UpdateBulkOrderMutation = { __typename: 'Mutation', updateBulkOrder: { __typename: 'BulkOrderObject', id: number, name: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, customerUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, template: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> }, orderSalesStats: Array<{ __typename: 'OrderSalesStatsObject', currency: string, sumTotal: number, sumPaidTotal: number, sumAdjustmentsTotal: number, sumRefundedTotal: number }>, orderStateCounts: Array<{ __typename: 'OrderStateCountsObject', state: Types.OrderState, count: number }> } };

export type ProcessBulkOrderMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  orderIds?: Types.InputMaybe<Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input']>;
}>;


export type ProcessBulkOrderMutation = { __typename: 'Mutation', processBulkOrder: { __typename: 'BulkOrderObject', id: number, name: string, createdAt: any, updatedAt: any, authorUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, customerUser?: { __typename: 'UserObject', id: number, firstName: string, lastName: string, phone?: string | null | undefined, email: string, createdAt: any, updatedAt: any } | null | undefined, template: { __typename: 'OrderTemplateObject', id: number, name: string, locale?: string | null | undefined, createdAt: any, updatedAt: any, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, currency: string, enabled: boolean, defaultLocale: string, updatedAt: any, createdAt: any, isDefault: boolean }, items: Array<{ __typename: 'OrderTemplateItemObject', id: number, quantity: number, unitPrice?: number | null | undefined, createdAt: any, updatedAt: any, resolvedUnitPrice: number, variant: { __typename: 'ProductVariantObject', id: number, name: string, code: string, product: { __typename: 'ProductObject', id: number, code: string, name: string } } }> }, orderSalesStats: Array<{ __typename: 'OrderSalesStatsObject', currency: string, sumTotal: number, sumPaidTotal: number, sumAdjustmentsTotal: number, sumRefundedTotal: number }>, orderStateCounts: Array<{ __typename: 'OrderStateCountsObject', state: Types.OrderState, count: number }> } };

export type CreateBulkOrderOrderMutationVariables = Types.Exact<{
  input: Types.CreateBulkOrderOrderInput;
}>;


export type CreateBulkOrderOrderMutation = { __typename: 'Mutation', createBulkOrderOrder: { __typename: 'OrderObject', id: number, number?: string | null | undefined, currency: string, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } } };

export const BulkOrderRowFragmentDoc = gql`
    fragment BulkOrderRow on BulkOrderObject {
  id
  name
  createdAt
  updatedAt
  authorUser {
    ...UserItem
  }
  customerUser {
    ...UserItem
  }
  template {
    name
    locale
    createdAt
    updatedAt
    channel {
      ...ChannelRow
    }
  }
  orderSalesStats {
    ...OrderSalesStats
  }
  orderStateCounts {
    ...OrderStateCounts
  }
}
    ${UserItemFragmentDoc}
${ChannelRowFragmentDoc}
${OrderSalesStatsFragmentDoc}
${OrderStateCountsFragmentDoc}`;
export const BulkOrderDetailFragmentDoc = gql`
    fragment BulkOrderDetail on BulkOrderObject {
  id
  name
  createdAt
  updatedAt
  authorUser {
    ...UserItem
  }
  customerUser {
    ...UserItem
  }
  template {
    ...OrderTemplateItem
  }
  orderSalesStats {
    ...OrderSalesStats
  }
  orderStateCounts {
    ...OrderStateCounts
  }
}
    ${UserItemFragmentDoc}
${OrderTemplateItemFragmentDoc}
${OrderSalesStatsFragmentDoc}
${OrderStateCountsFragmentDoc}`;
export const BulkOrderDocument = gql`
    query BulkOrder($id: Int!) {
  bulkOrder(id: $id) {
    ...BulkOrderDetail
  }
}
    ${BulkOrderDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BulkOrderGQL extends Apollo.Query<BulkOrderQuery, BulkOrderQueryVariables> {
    document = BulkOrderDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BulkOrderRowsDocument = gql`
    query BulkOrderRows($filter: BulkOrderFilterInput, $page: PageInput, $sort: SortInput) {
  items: bulkOrders(filter: $filter, page: $page, sort: $sort) {
    ...BulkOrderRow
  }
  totalItemsCount: bulkOrdersCount(filter: $filter)
}
    ${BulkOrderRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BulkOrderRowsGQL extends Apollo.Query<BulkOrderRowsQuery, BulkOrderRowsQueryVariables> {
    document = BulkOrderRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBulkOrderDocument = gql`
    mutation CreateBulkOrder($input: CreateBulkOrderInput!) {
  createBulkOrder(input: $input) {
    ...BulkOrderDetail
  }
}
    ${BulkOrderDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBulkOrderGQL extends Apollo.Mutation<CreateBulkOrderMutation, CreateBulkOrderMutationVariables> {
    document = CreateBulkOrderDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBulkOrderDocument = gql`
    mutation UpdateBulkOrder($input: UpdateBulkOrderInput!) {
  updateBulkOrder(input: $input) {
    ...BulkOrderDetail
  }
}
    ${BulkOrderDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBulkOrderGQL extends Apollo.Mutation<UpdateBulkOrderMutation, UpdateBulkOrderMutationVariables> {
    document = UpdateBulkOrderDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProcessBulkOrderDocument = gql`
    mutation ProcessBulkOrder($id: Int!, $orderIds: [Int!]) {
  processBulkOrder(id: $id, orderIds: $orderIds) {
    ...BulkOrderDetail
  }
}
    ${BulkOrderDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProcessBulkOrderGQL extends Apollo.Mutation<ProcessBulkOrderMutation, ProcessBulkOrderMutationVariables> {
    document = ProcessBulkOrderDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBulkOrderOrderDocument = gql`
    mutation CreateBulkOrderOrder($input: CreateBulkOrderOrderInput!) {
  createBulkOrderOrder(input: $input) {
    ...OrderItem
  }
}
    ${OrderItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBulkOrderOrderGQL extends Apollo.Mutation<CreateBulkOrderOrderMutation, CreateBulkOrderOrderMutationVariables> {
    document = CreateBulkOrderOrderDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }