import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[xDataAction]',
})
export class DataActionDefinitionDirective {
  @Input('xDataAction')
  id: string;

  @Input('xDataActionPrimary')
  primary = false;

  constructor(public template: TemplateRef<any>) {}
}
