import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { IProductVariantItemObject, ProductVariantService } from '@x/ecommerce/domain-client';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProductVariantItemCollectionProvider
  implements IDataCollectionProvider<IProductVariantItemObject>
{
  constructor(private productVariantService: ProductVariantService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IProductVariantItemObject>> {
    return this.productVariantService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: string | number,
  ): IProductVariantItemObject | Observable<IProductVariantItemObject> {
    return this.productVariantService.fetchItem(Number(id));
  }

  toId(model: IProductVariantItemObject): string | number {
    return model.id;
  }

  toString(model: IProductVariantItemObject): string {
    return model.name === model.product.name
      ? `${model.name}`
      : `${model.product.name} - ${model.name}`;
  }
}
