import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { OrderItemFragmentDoc } from './order.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CartsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CartsQuery = { __typename: 'Query', orders: Array<{ __typename: 'OrderObject', id: number, number?: string | null | undefined, currency: string, channel: { __typename: 'ChannelObject', id: number, code: string, name: string, color: string } }> };

export type CartCreateMutationVariables = Types.Exact<{
  data: Types.CreateCartInput;
}>;


export type CartCreateMutation = { __typename: 'Mutation', createCart: { __typename: 'OrderObject', id: number } };

export type CartUpdateItemMutationVariables = Types.Exact<{
  data: Types.UpdateCartItemInput;
}>;


export type CartUpdateItemMutation = { __typename: 'Mutation', updateCartItem: { __typename: 'OrderObject', id: number } };

export type CartRemoveItemMutationVariables = Types.Exact<{
  data: Types.RemoveCartItemInput;
}>;


export type CartRemoveItemMutation = { __typename: 'Mutation', removeCartItem: { __typename: 'OrderObject', id: number } };

export type CartAssignAddressMutationVariables = Types.Exact<{
  data: Types.UpdateCartAddressInput;
}>;


export type CartAssignAddressMutation = { __typename: 'Mutation', assignCartAddress: { __typename: 'OrderObject', id: number } };

export type CartAssignCloneAddressMutationVariables = Types.Exact<{
  data: Types.UpdateCartCloneAddressInput;
}>;


export type CartAssignCloneAddressMutation = { __typename: 'Mutation', assignCartCloneAddress: { __typename: 'OrderObject', id: number } };

export type CartAssignCustomerMutationVariables = Types.Exact<{
  data: Types.UpdateCartCustomerInput;
}>;


export type CartAssignCustomerMutation = { __typename: 'Mutation', assignCartCustomer: { __typename: 'OrderObject', id: number } };

export type CartAssignCouponCodeMutationVariables = Types.Exact<{
  data: Types.UpdateCartCouponCodeInput;
}>;


export type CartAssignCouponCodeMutation = { __typename: 'Mutation', assignCartCouponCode: { __typename: 'OrderObject', id: number } };

export type CartAssignReferrerCodeMutationVariables = Types.Exact<{
  data: Types.UpdateCartReferrerCodeInput;
}>;


export type CartAssignReferrerCodeMutation = { __typename: 'Mutation', assignCartReferrerCode: { __typename: 'OrderObject', id: number } };

export type CartUpdateGiftOptionsMutationVariables = Types.Exact<{
  data: Types.UpdateCartGiftOptionsInput;
}>;


export type CartUpdateGiftOptionsMutation = { __typename: 'Mutation', updateCartGiftOptions: { __typename: 'OrderObject', id: number } };

export type CartCheckoutMutationVariables = Types.Exact<{
  data: Types.CheckoutCartInput;
}>;


export type CartCheckoutMutation = { __typename: 'Mutation', checkoutCart: { __typename: 'OrderObject', id: number } };

export type CartEnquireMutationVariables = Types.Exact<{
  data: Types.EnquireCartInput;
}>;


export type CartEnquireMutation = { __typename: 'Mutation', enquireCart: { __typename: 'OrderObject', id: number } };

export type UpdateCartInstructionsMutationVariables = Types.Exact<{
  data: Types.UpdateCartInstructionsInput;
}>;


export type UpdateCartInstructionsMutation = { __typename: 'Mutation', updateCartInstructions: { __typename: 'OrderObject', id: number } };

export type AssignCartAdjustmentMutationVariables = Types.Exact<{
  data: Types.AssignCartAdjustmentInput;
}>;


export type AssignCartAdjustmentMutation = { __typename: 'Mutation', assignCartAdjustment: { __typename: 'OrderObject', id: number } };

export type ProcessCartMutationVariables = Types.Exact<{
  orderId: Types.Scalars['Int']['input'];
}>;


export type ProcessCartMutation = { __typename: 'Mutation', processCart: { __typename: 'OrderObject', id: number } };

export type RecreateCartMutationVariables = Types.Exact<{
  orderId: Types.Scalars['Int']['input'];
  skipAgreements?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type RecreateCartMutation = { __typename: 'Mutation', recreateCart: { __typename: 'OrderObject', id: number } };

export const CartsDocument = gql`
    query Carts {
  orders(filter: {states: [CART]}, page: {size: 10}) {
    ...OrderItem
  }
}
    ${OrderItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartsGQL extends Apollo.Query<CartsQuery, CartsQueryVariables> {
    document = CartsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartCreateDocument = gql`
    mutation CartCreate($data: CreateCartInput!) {
  createCart(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartCreateGQL extends Apollo.Mutation<CartCreateMutation, CartCreateMutationVariables> {
    document = CartCreateDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartUpdateItemDocument = gql`
    mutation CartUpdateItem($data: UpdateCartItemInput!) {
  updateCartItem(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartUpdateItemGQL extends Apollo.Mutation<CartUpdateItemMutation, CartUpdateItemMutationVariables> {
    document = CartUpdateItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartRemoveItemDocument = gql`
    mutation CartRemoveItem($data: RemoveCartItemInput!) {
  removeCartItem(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartRemoveItemGQL extends Apollo.Mutation<CartRemoveItemMutation, CartRemoveItemMutationVariables> {
    document = CartRemoveItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartAssignAddressDocument = gql`
    mutation CartAssignAddress($data: UpdateCartAddressInput!) {
  assignCartAddress(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAssignAddressGQL extends Apollo.Mutation<CartAssignAddressMutation, CartAssignAddressMutationVariables> {
    document = CartAssignAddressDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartAssignCloneAddressDocument = gql`
    mutation CartAssignCloneAddress($data: UpdateCartCloneAddressInput!) {
  assignCartCloneAddress(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAssignCloneAddressGQL extends Apollo.Mutation<CartAssignCloneAddressMutation, CartAssignCloneAddressMutationVariables> {
    document = CartAssignCloneAddressDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartAssignCustomerDocument = gql`
    mutation CartAssignCustomer($data: UpdateCartCustomerInput!) {
  assignCartCustomer(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAssignCustomerGQL extends Apollo.Mutation<CartAssignCustomerMutation, CartAssignCustomerMutationVariables> {
    document = CartAssignCustomerDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartAssignCouponCodeDocument = gql`
    mutation CartAssignCouponCode($data: UpdateCartCouponCodeInput!) {
  assignCartCouponCode(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAssignCouponCodeGQL extends Apollo.Mutation<CartAssignCouponCodeMutation, CartAssignCouponCodeMutationVariables> {
    document = CartAssignCouponCodeDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartAssignReferrerCodeDocument = gql`
    mutation CartAssignReferrerCode($data: UpdateCartReferrerCodeInput!) {
  assignCartReferrerCode(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartAssignReferrerCodeGQL extends Apollo.Mutation<CartAssignReferrerCodeMutation, CartAssignReferrerCodeMutationVariables> {
    document = CartAssignReferrerCodeDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartUpdateGiftOptionsDocument = gql`
    mutation CartUpdateGiftOptions($data: UpdateCartGiftOptionsInput!) {
  updateCartGiftOptions(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartUpdateGiftOptionsGQL extends Apollo.Mutation<CartUpdateGiftOptionsMutation, CartUpdateGiftOptionsMutationVariables> {
    document = CartUpdateGiftOptionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartCheckoutDocument = gql`
    mutation CartCheckout($data: CheckoutCartInput!) {
  checkoutCart(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartCheckoutGQL extends Apollo.Mutation<CartCheckoutMutation, CartCheckoutMutationVariables> {
    document = CartCheckoutDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartEnquireDocument = gql`
    mutation CartEnquire($data: EnquireCartInput!) {
  enquireCart(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartEnquireGQL extends Apollo.Mutation<CartEnquireMutation, CartEnquireMutationVariables> {
    document = CartEnquireDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCartInstructionsDocument = gql`
    mutation UpdateCartInstructions($data: UpdateCartInstructionsInput!) {
  updateCartInstructions(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCartInstructionsGQL extends Apollo.Mutation<UpdateCartInstructionsMutation, UpdateCartInstructionsMutationVariables> {
    document = UpdateCartInstructionsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignCartAdjustmentDocument = gql`
    mutation AssignCartAdjustment($data: AssignCartAdjustmentInput!) {
  assignCartAdjustment(data: $data) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignCartAdjustmentGQL extends Apollo.Mutation<AssignCartAdjustmentMutation, AssignCartAdjustmentMutationVariables> {
    document = AssignCartAdjustmentDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProcessCartDocument = gql`
    mutation ProcessCart($orderId: Int!) {
  processCart(orderId: $orderId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProcessCartGQL extends Apollo.Mutation<ProcessCartMutation, ProcessCartMutationVariables> {
    document = ProcessCartDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RecreateCartDocument = gql`
    mutation RecreateCart($orderId: Int!, $skipAgreements: Boolean) {
  recreateCart(orderId: $orderId, skipAgreements: $skipAgreements) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RecreateCartGQL extends Apollo.Mutation<RecreateCartMutation, RecreateCartMutationVariables> {
    document = RecreateCartDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }