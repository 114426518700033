<ng-container *ngIf="view">
  <div class="content">
    <ng-content></ng-content>
  </div>
  <div class="control">
    <!-- <button
      mat-icon-button
      type="button"
      matTooltip="Filter Options"
      [matMenuTriggerFor]="settingsMenu"
    >
      <fa-icon icon="cog"></fa-icon>
    </button> -->
    <button mat-icon-button type="button" (click)="view.refresh()" matTooltip="Refresh">
      <fa-icon icon="sync"></fa-icon>
    </button>
    <button mat-icon-button type="button" (click)="view.reset()" matTooltip="Reset All Filters">
      <fa-icon icon="backspace"></fa-icon>
    </button>
  </div>
  <!-- <div class="control">
    <button
      mat-icon-button
      [disabled]="!view.queryHistory.canUndo()"
      type="button"
      (click)="view.queryHistory.undo()"
      matTooltip="Undo"
    >
      <fa-icon icon="undo"></fa-icon>
    </button>
    <button
      mat-icon-button
      [disabled]="!view.queryHistory.canRedo()"
      type="button"
      (click)="view.queryHistory.redo()"
      matTooltip="Redo"
    >
      <fa-icon icon="redo"></fa-icon>
    </button>
  </div> -->
  <div class="control" *ngIf="columnOptions.length && _columnsEnabled">
    <mat-select
      class="column-select"
      [value]="view.selectedColumns"
      (selectionChange)="onColumnsChange($event.value)"
      multiple="true"
      matTooltip="Select Columns To Display"
    >
      <mat-select-trigger>
        <fa-icon icon="columns"></fa-icon>
        <span>{{ view.selectedColumns.length }} of {{ columnOptions.length }} Columns</span>
      </mat-select-trigger>
      <mat-option *ngFor="let option of columnOptions" [value]="option.id">
        {{ option.title }}
      </mat-option>
    </mat-select>
  </div>
  <ng-container *ngIf="sortOptions && _sortEnabled">
    <div class="control">
      <div class="sort-label">Sort by</div>
      <div class="sort-label-column">{{ sortTitle }}</div>
      <button
        mat-icon-button
        type="button"
        class="sort-order"
        [matMenuTriggerFor]="sortColumnMenu"
        matTooltip="Change Sort Order"
      >
        <fa-icon [icon]="view.sortOrder === 'asc' ? 'sort-alpha-down' : 'sort-alpha-up'"></fa-icon>
      </button>
    </div>
  </ng-container>

  <mat-paginator
    *ngIf="_paginationEnabled"
    [length]="view.totalItemsCount"
    [pageIndex]="view.pageIndex"
    [pageSize]="view.pageSize"
    [pageSizeOptions]="view.pageSizes"
    (page)="onPage($event)"
    itemsPerPageLabel=""
  ></mat-paginator>

  <mat-menu #sortColumnMenu>
    <ng-template matMenuContent>
      <span mat-subheader>Sort By:</span>
      <mat-divider></mat-divider>
      <button
        type="button"
        mat-menu-item
        *ngFor="let option of sortOptions"
        [value]="option.id"
        [matMenuTriggerFor]="sortOrderMenu"
        [matMenuTriggerData]="{ $implicit: option.id }"
      >
        {{ option.title }}
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #sortOrderMenu>
    <ng-template matMenuContent let-columnId>
      <button type="button" mat-menu-item (click)="onSortChange(columnId, 'asc')">
        <fa-icon icon="sort-alpha-down"></fa-icon>
        Ascending (A-Z)
      </button>
      <button type="button" mat-menu-item (click)="onSortChange(columnId, 'desc')">
        <fa-icon icon="sort-alpha-down"></fa-icon>
        Descending (Z-A)
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #settingsMenu>
    <button mat-menu-item type="button" (click)="view.refresh()">
      <fa-icon icon="sync"></fa-icon>
      Refresh
    </button>
    <button mat-menu-item type="button" (click)="view.reset()">
      <fa-icon icon="backspace"></fa-icon>
      Reset All Filters
    </button>
  </mat-menu>
</ng-container>
