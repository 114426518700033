import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { DataTableView, IColumnDefinition, ISortDefinition } from '@x/common/data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'x-data-table-controls',
  templateUrl: './data-table-controls.component.html',
  styleUrls: ['./data-table-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-data-table-controls',
  },
})
export class DataTableControlsComponent implements OnInit, OnDestroy {
  @Input()
  view?: DataTableView<any, any, any, any>;

  @Output('page')
  pageEmitter = new EventEmitter<PageEvent>();

  @Input()
  sortOptions: ISortDefinition[] = [];

  @Input()
  columnOptions: IColumnDefinition[] = [];

  @Input()
  set pagination(pagination: any) {
    this._paginationEnabled = coerceBooleanProperty(pagination);
  }

  @Input()
  set sort(sort: any) {
    this._sortEnabled = coerceBooleanProperty(sort);
  }

  @Input()
  set columns(columns: any) {
    this._columnsEnabled = coerceBooleanProperty(columns);
  }

  sortTitle = 'Default';

  _paginationEnabled = true;
  _sortEnabled = true;
  _columnsEnabled = true;

  private _destroy$ = new Subject<void>();

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.view) {
      throw new Error(`DataTableControlsComponent requires a DataTableView in order to function.`);
    }

    this.columnOptions = this.view.columns;
    this.sortOptions = this.view.sorts;

    this.view
      .stateChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.updateSortTitle();
        this.changeRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onPage(event: PageEvent) {
    this.pageEmitter.emit(event);

    this.view?.setPage(event.pageIndex, event.pageSize);
  }

  onColumnsChange(columns: string[]) {
    if (this.view) {
      this.view.setSelectedColumns(columns);
    }
  }

  onSortColumnChange(column: string) {
    if (this.view) {
      this.view.sortColumn = column;
    }
  }

  onSortChange(column: string, order: 'asc' | 'desc') {
    if (this.view) {
      this.view.setSort(column, order);
    }
  }

  updateSortTitle() {
    this.sortTitle = this.getCurrentSortColumnLabel();
  }

  getCurrentSortColumnLabel() {
    return this.sortOptions?.find((c) => c.id === this.view?.sortColumn)?.title ?? 'Default';
  }
}
